import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/ryan.parr/Documents/Apps/sprinklr.design/src/templates/mdx-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bar`}</h2>
    <p>{`With an aim to give different users their own personal environment, we have named our design system Space. It is an adaptable and reusable system used to assemble the Sprinklr platform which has been designed to be personalised for each user to achieve their individual tasks and goals. It also helps maximise efficiency and productivity for our design teams. Following are the principles that arch the guidelines:`}</p>
    <h2>{`the sprinklr platform`}</h2>
    <p>{`At Sprinklr, we are re-imagining a new class of enterprise software under the umbrella of `}<inlineCode parentName="p">{`Customer Experience Platform (CXM)`}</inlineCode>{` which is a culmination of five of our core offerings i.e Marketing, Advertising, Research, Commerce and Care, all of which work together synchronously to help brands create best experience for their customers.`}</p>
    <h2>{`our design system principles`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      